"use client";

import React, { useState, useCallback } from "react";
import "../styles/StoreLocator.css";
import stores from "../stores.json";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
} from "@vis.gl/react-google-maps";

const StoreLocator = () => {
  const [open, setOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [apiKeyValid, setApiKeyValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const centerStart =
    stores.length > 0
      ? { lat: stores[0].lat, lng: stores[0].lng }
      : { lat: 0, lng: 0 };
  const [center, setCenter] = useState(centerStart);

  const handleMarkerClick = (store) => {
    setSelectedStore(store);
    setOpen(true);
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const handleApiError = () => {
    console.error("Invalid API Key or other API error");
    setApiKeyValid(false);
  };

  const handleGeocoderResult = (results, status) => {
    setLoading(false);
    if (status === "OK") {
      if (results[0]) {
        const location = results[0].geometry.location;
        setCenter({ lat: location.lat(), lng: location.lng() });
      } else {
        console.error("No results found for the entered zip code.");
      }
    } else {
      console.error("Geocoder failed due to: " + status);
    }
  };

  const handleZipSubmit = (e) => {
    e.preventDefault();
    const zipCode = e.target.elements.zipCode.value.trim();
    if (!/^\d{5}$/.test(zipCode)) {
      alert("Please enter a valid zip code.");
      return;
    }
    setLoading(true);
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: zipCode }, handleGeocoderResult);
  };

  const handleCameraChange = useCallback((ev) => {
    const { center: newCenter } = ev.detail;
    setCenter(newCenter);
  }, []);

  return (
    <APIProvider
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      onLoad={handleMapLoad}
      onError={handleApiError}
    >
      <div className="locator-container">
        <p className="locator-header">Find us at a store near you</p>
        <form className="zip-code-form" onSubmit={handleZipSubmit}>
          <input
            name="zipCode"
            placeholder="Zip Code"
            className="zip-code-input"
            type="text"
          />
          <button className="zip-submit" type="submit">
            Enter
          </button>
        </form>
        {loading && <p>Loading...</p>}
        {apiKeyValid ? (
          <div className="map-container">
            <Map
              defaultZoom={12}
              defaultCenter={centerStart}
              center={center}
              mapId="ba49f6a6c36bde5d"
              options={{ scrollwheel: true, draggable: true }}
              onLoad={handleMapLoad}
              onCameraChanged={handleCameraChange}
              streetViewControl={false}
              mapTypeControl={false}
            >
              {mapLoaded &&
                stores.map((store) => (
                  <AdvancedMarker
                    key={store.id}
                    position={{ lat: store.lat, lng: store.lng }}
                    onClick={() => handleMarkerClick(store)}
                  >
                    <Pin
                      background={"var(--color-raja-red)"}
                      borderColor={"black"}
                      glyphColor={"var(--color-cream)"}
                    />
                  </AdvancedMarker>
                ))}
              {open && selectedStore && (
                <InfoWindow
                  position={{ lat: selectedStore.lat, lng: selectedStore.lng }}
                  options={{ pixelOffset: new window.google.maps.Size(0, -25) }}
                  onCloseClick={() => setOpen(false)}
                  className="marker-window"
                >
                  <div className="marker-text">
                    <p className="marker-title">{selectedStore.name}</p>
                    <a
                      alt="google maps link"
                      href={selectedStore.link}
                      rel="noreferrer"
                      target="_blank"
                      className="marker-link"
                    >
                      <p className="marker-address">{selectedStore.address}</p>
                    </a>
                  </div>
                </InfoWindow>
              )}
            </Map>
          </div>
        ) : (
          <p className="error-message">
            There was an error loading the map. Please check your API key and
            try again.
          </p>
        )}
      </div>
    </APIProvider>
  );
};

export default StoreLocator;