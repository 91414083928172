import { useState, useEffect } from 'react';

const useImagesPreload = (srcArray, includeTimeout = false) => {
  const [loaded, setLoaded] = useState(false);
  const [isLcpLoaded, setIsLcpLoaded] = useState(false); // Track LCP image load state

  const imageLoader = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  };

  useEffect(() => {
    const preloadNonLcpImages = async () => {
      const nonLcpImages = srcArray.filter((src) => src !== srcArray[0]); // Assuming first image is LCP
      await Promise.all(nonLcpImages.map((src) => imageLoader(src)));
      setLoaded(true);
    };

    const preloadLcpImage = () => {
      const lcpImage = new Image();
      lcpImage.src = srcArray[0]; // Assuming first image is LCP
      lcpImage.loading = "eager";
      lcpImage.fetchpriority = "high";
      lcpImage.onload = () => {
        setIsLcpLoaded(true);
        preloadNonLcpImages();
      };
    };

    if (srcArray.length > 0) {
      preloadLcpImage();
    }

    if (includeTimeout) {
      const timeout = setTimeout(() => {
        setLoaded(true);
      }, 5000); // Timeout after 5 seconds

      return () => clearTimeout(timeout);
    }
  }, [srcArray, includeTimeout]);

  return { loaded, isLcpLoaded }; // Return both states
};

export default useImagesPreload;
