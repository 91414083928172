import React, { useState } from "react";
import "./styles/App.css";

/* Import Images */
import spinning_logo from "./images/RAJA_loop.gif";
import instagram_logo from "./images/instagram.webp";
import raja_can from "./images/Can_Mockup_resized.webp"; // Import the image directly

/* Components */
import Header from "./components/Header";
import Spinner from "./components/Spinner";
import Footer from "./components/Footer";
import SubmitModal from "./components/SubmitModal";
import ErrorModal from "./components/ErrorModal";
import StoreLocator from "./components/StoreLocator";

/* Custom Hook */
import useImagesPreload from "./hooks/useImagesPreload"; // Preload function

function App() {
  const imageSources = [raja_can, spinning_logo, instagram_logo]; // Assuming raja_can is LCP
  const { loaded, isLcpLoaded } = useImagesPreload(imageSources, false); // Set to true for a timeout

  const [toggleSubmit, setSubmit] = useState(false);
  const [toggleError, setError] = useState(false);

  return (
    <div className="App">
      {!isLcpLoaded ? (
        <Spinner />
      ) : (
        <div className="main-container">
          {toggleSubmit && <SubmitModal setSubmit={setSubmit} />}
          {toggleError && <ErrorModal setError={setError} />}
          {(toggleSubmit || toggleError) && (
            <div className="modal-backdrop"></div>
          )}
          <Header
            raja_can={raja_can}
            spinning_logo={spinning_logo}
          />
          <StoreLocator />
          <Footer
            footer_logo={spinning_logo}
            instagram_logo={instagram_logo}
            setSubmit={setSubmit}
            setError={setError}
          />
        </div>
      )}
      {!loaded && <Spinner />}
    </div>
  );
}

export default App;
