// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "drinkraja-6b17a.firebaseapp.com",
  databaseURL: "https://drinkraja-6b17a-default-rtdb.firebaseio.com",
  projectId: "drinkraja-6b17a",
  storageBucket: "drinkraja-6b17a.appspot.com",
  messagingSenderId: "330904979231",
  appId: "1:330904979231:web:fec349c836a282abf7122a",
  measurementId: "G-M3WQSKNW5H"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

// Sign in anonymously
signInAnonymously(auth)
  .then(() => {
    console.log("Signed in anonymously");
  })
  .catch((error) => {
    console.error("Error signing in anonymously: ", error);
  });

// Check authentication state
onAuthStateChanged(auth, (user) => {
  if (user) {
    console.log("User is signed in:", user);
  } else {
    console.log("No user is signed in");
  }
});

export { db, auth };
