import React, { useState } from "react";
import "../styles/Footer.css";

/* Database Functions */
import { db } from "../firebase";
import { addDoc, collection, query, where, getDocs, serverTimestamp } from "firebase/firestore";

export default function Footer({
  footer_logo,
  instagram_logo,
  setSubmit,
  setError,
}) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    // Simple email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email === "") {
      setEmailError("Email is required.");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    setEmailError("");
    setLoading(true);

    try {
      // Check if the email already exists
      const q = query(collection(db, "Emails"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setEmailError("This email is already subscribed.");
      } else {
        await addDoc(collection(db, "Emails"), {
          email: email,
          timestamp: serverTimestamp(),
        });
        setSubmit(true);
        setEmail("");
      }
    } catch (err) {
      setError(true);
      alert("Error adding document: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="footer-container">
      <div className="footer-span">
        <form className="form" onSubmit={handleSubmit}>
          <h2 className="form-title">
            Spice up your life.
            <br />
            Sign up for our newsletter.
          </h2>
          <input
            className="inputBox"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <p className="error-message">{emailError}</p>}
          <button className="submitButton" type="submit" disabled={loading}>
            {loading ? "Submitting..." : "Spice Me Up"}
          </button>
        </form>
        <div className="logo-container">
          <img
            className="footer_logo"
            src={footer_logo}
            alt="Animated gif of Raja Logo"
          />
          <div className="instagram-container">
            <p className="follow-text">
              Follow our social media
              <br />
              shenanigans
            </p>
            <a
              href="https://www.instagram.com/drink.raja/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="instagram_logo"
                src={instagram_logo}
                alt="Instagram Logo with link to the Raja instagram"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>&copy; 2024 Raja Brands LLC</p>
      </div>
    </div>
  );
}
