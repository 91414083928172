import React, { useEffect } from "react";
import "../styles/Modal.css";

function SubmitModal({ setSubmit }) {
	useEffect(() => {
		// Disable scrolling when the modal mounts
		document.body.style.overflow = "hidden";

		// Re-enable scrolling when the modal unmounts or when it's closed
		return () => {
			document.body.style.overflow = "auto";
		};
	}, []);

	const handleClose = () => {
		setSubmit(false);
	};

	return (
		<div className="modal-container">
			<p className="modal-text">
				Thanks for signing up
			</p>
			<button className="close-button" onClick={handleClose}>
				Close
			</button>
		</div>
	);
}

export default SubmitModal;
