import React, { useEffect } from "react";
import "../styles/Modal.css";

function ErrorModal({ setError }) {
	useEffect(() => {
		// Disable scrolling when the modal mounts
		document.body.style.overflow = "hidden";

		// Re-enable scrolling when the modal unmounts or when it's closed
		return () => {
			document.body.style.overflow = "auto";
		};
	}, []);

	const handleClose = () => {
		setError(false);
	};

	return (
		<div className="modal-container">
			<p className="modal-text">
				Invalid email
				<br />
				Response was not submitted
			</p>
			<button className="close-button" onClick={handleClose}>
				Close
			</button>
		</div>
	);
}

export default ErrorModal;
