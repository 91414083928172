import React from "react";
import "../styles/Header.css";

export default function Header({ raja_can }) {
	return (
		<div className="header-container">
			<img
				className="raja-can"
				src={raja_can}
				alt="A can of Raja Chai Latte."
			/>
			<p className="header-text">
				Say hi to a new chai.
			</p>
			<div className="scroll-arrow">↓</div>
		</div>
	);
}
